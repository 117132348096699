<template>
  <div class="file-item" 
    :class="{'checked': checked, 'no-checkbox': !showCheckbox}" 
    @dblclick.stop="dbClickFileName" 
    @click.stop="clickFileItem"
  >
    <div class="file-info ellipsis" @click.stop="">
      <div class="checkbox-wrap"
        @click.stop="clickCheckbox" 
        @dblclick.stop=""
      >
        <div 
          v-if="showCheckbox"
          class="checkbox" 
          :class="{'checked': checked}" 
        >
          <label for="check">
            <i class="el-icon-check"></i>
          </label>
        </div>
      </div>

      <!-- 文件图标 -->
      <template v-if="!isShare">
        <img :src="name | formatFileType(isdir)" class="file-icon"> 
      </template>
      <template v-if="isShare">
        <img :src="FILE_TYPES.FILE_TYPE_FOLDER" class="file-icon"> 
      </template>
      
      <!-- 文件名称 -->
      <span 
        :title="name"
        class="file-name ellipsis"
        :class="{'folder': isdir}"
        @click.stop="clickFileName">
        {{ name }}
      </span>
    </div>
    
    <!-- 分享文件的列表下没有大小 -->
    <template v-if="!isShare">
      <div class="file-size" v-if="isdir">-</div>
      <div class="file-size" v-if="!isdir">{{ size | bytesToSize }}</div>
    </template>
    <template v-if="isShare">
      <div class="file-view">{{file.view || 0}}</div>
      <div class="file-mark">{{file.mark || 0}}</div>
    </template>
    <div class="file-time">{{ mtime | formatDate('YYYY/MM/DD HH:mm')}}</div>
    <template v-if="isShare">
      <div class="file-status">有效</div>
    </template>
    <div class="file-folder" v-if="searchView">
      <i class="el-icon-folder-opened" @click.stop="chooseParentFolder"></i>
    </div>
    <div class="file-folder" v-if="isShare">
      <i class="el-icon-link" @click.stop="copyLink"></i>
    </div>
  </div>
</template>

<script>
import FILE_TYPES from '../constants/filetype'

export default {
  props: {
    file: {
      type: Object,
      default: () => ({})
    },
    checked: {
      type: Boolean,
      default: false
    },
    showCheckbox: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'normal'
    }, 
    view: {
      type: String,
      default: 'normal'
    },
  },
  data () {
    return {
      FILE_TYPES,
    }
  },
  computed: {
    fid() {
      if (this.isShare) return this.file.shareid
      return this.file.fid
    },
    parentfid () {
      return this.file.parentfid
    },
    isdir () {
      return this.file.isdir || this.file.isDir
    },
    mtime() {
      if (this.isDeleted) return this.file.createtime
      if (this.isShare) return this.file.updatetime
      return this.file.server_mtime
    },
    name () {
      return this.file.name || ''
    },
    size () {
      return this.file.size
    },
    isDeleted () {
      return this.type === 'delete'
    },
    isShare () {
      return this.type === 'share'
    },
    searchView () {
      return this.view === 'search'
    },
  },
  methods: {
    async dbClickFileName() {
      if (this.isdir) {
        return this.$emit('open-folder', { 
          fid: this.fid, 
          name: this.name,
        })
      }
    },
    clickFileName () {
      if (this.isdir) {
        this.$emit('open-folder', { 
          fid: this.fid, 
          name: this.name,
        })
      } else {
        this.clickFileItem()
      }
    },
    clickFileItem () {
      this.$emit('choose', { 
        fid: this.fid, 
        name: this.name,
        reset: true,
      })
    },
    clickCheckbox () {
      this.$emit(this.checked ? 'unchoose' : 'choose', { 
        fid: this.fid, 
        name: this.name,
      })
    },
    async chooseParentFolder () {
      this.$emit('open-folder', {
        name: this.name,
        fid: this.parentfid,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';

.file-item {
  display: flex;
  align-items: center;
  padding: 4px 10px 4px 2px;
  transition: ease 0.2s all;
  font-size: 12px;
  background-color: #fff;
  border-bottom: 0.5px solid #f8f8f8;
  &.no-checkbox {
    padding: 10px 10px 10px 0px;
  }

  &:hover {
    background-color: rgba($theme-color, 0.1) !important;
  }

  &.checked {
    border-radius: 3px;
    background-color: rgba($theme-color, 0.8) !important;
    border-color: rgba($theme-color, 0.3) !important;

    .file-info,
    .file-size,
    .file-time,
    .file-view,
    .file-mark,
    .file-status,
    .file-folder {
      color: #fff;
    }

    .checkbox {
      &.checked {
        border-color: #fff;
        background-color: #fff;

        i {
          color: $theme-color;
        }
      }
    }

    .file-name.folder {
      &:hover {
        color: #fff;
      }
    }
  }

  .file-info {
    display: flex;
    flex: auto;
    align-items: center;
    margin-right: 60px;
  }

  .checkbox-wrap {
    padding: 12px;
  }
  .checkbox {
    width: 14px;
    height: 14px;
    cursor: pointer;
    line-height: 12px;
    border-radius: 2px;
    border: 1px solid #C9C9C9;
    background-color: #fff;
    transition: all ease 0.2s;

    i {
      color: #fff;
      font-size: 12px;
      font-weight: bold;
    }

    &:hover {
      border-color: $theme-color;
    }

    &.checked {
      border-color: $theme-color;
      background-color: $theme-color;
    }
  }

  .file-icon {
    max-width: 16px;
    max-height: 16px;
    margin-right: 8px;
  }

  .file-name {
    display: inline-block;
    max-width: 600px;
    user-select: none;
    cursor: pointer;

    &.folder {
      transition: color 0.2s ease;

      &:hover {
        color: $theme-color;
      }
    }
  }
  .file-view,
  .file-mark {
    width: 60px;
    color: #666;
    flex: none;
    padding-left: 4px;
  }
  .file-status {
    width: 90px;
    color: #666;
    flex: none;
  }

  .file-time,
  .file-size {
    width: 16%;
    color: #666;
    flex: none;
  }
  .file-folder {
    width: 70px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    flex: none;
  }
}
</style>