import { request } from './_base'
import { USER_API_HOST } from '@/constants/common'

export const sharePanFiles = async ({ fids }) => {
  return await request({
    method: 'POST',
    url: `${USER_API_HOST}/api/v1/share/pan`,
    data: { fids },
    headers: {
      Authorization: global.token
    }
  })
}

export const cancelShare = async ({ shareIds }) => {
  return await request({
    method: 'POST',
    url: `${USER_API_HOST}/api/v1/share/pan/cancel`,
    data: { shareIds },
    headers: {
      Authorization: global.token
    }
  })
}

export const getShareList = async () => {
  return await request({
    url: `${USER_API_HOST}/api/v1/share/pan`,
    headers: {
      Authorization: global.token
    }
  })
}

export const getShareInfo = async ({ shareId, sharePwd }) => {
  return await request({
    url: `${USER_API_HOST}/api/v1/share/info`,
    params: {
      shareId,
      sharePwd,
    }
  })
}

export const getShareFileList = async ({ shareId, parentfid }) => {
  return await request({
    url: `${USER_API_HOST}/api/v1/share/files`,
    params: {
      shareId,
      parentfid
    }
  })
}