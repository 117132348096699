<template>
  <div class="share-page">
    <div class="nav-bar">
      <div class="inner">
        <img src="https://res.oodcd.cn/1second/images/logo_with_text.png" class="logo">
      </div>
    </div>
    <div class="share-content">
      <div class="header" v-if="shareInfo.nickname">
        <div class="left">
          <img class="avatar" :src="defaultAvatar">
          <div class="share-info">
            <p class="title">
              {{shareInfo.nickname}}共享的文件 · 
              <span class="danger" v-if="!shareInfo.expired">{{shareInfo.expiretime | fromNow}}过期</span>
              <span class="danger" v-if="shareInfo.expired">已失效</span>
            </p>
            <el-breadcrumb class="ellipsis" separator-class="el-icon-arrow-right">
              <template v-for="(item, index) in pickerBreads">
                <el-breadcrumb-item
                  class ="bread-item"
                  :key="item.path"
                  v-if="pickerBreadsLength <= 5 || (pickerBreadsLength > 5 && (pickerBreadsLength - index < 3 || index < 3))"
                >
                  <span @click="chooseBread(item)">{{item.name}}</span> 
                </el-breadcrumb-item>
                <el-breadcrumb-item
                  class ="bread-item"
                  :key="item.fid"
                  v-if="index == 3 && pickerBreadsLength >= 6"
                >
                  <span>......</span> 
                </el-breadcrumb-item>
              </template>
            </el-breadcrumb>
          </div>
        </div>
        <el-button 
          icon="el-icon-folder" 
          type="primary" 
          size="small"
          @click.stop="openApp"
        >转存到我的网盘</el-button>
      </div> 
      <template v-if="shareInfo.nickname">
       <FileList 
          v-if="!shareInfo.expired"
          @choose="chooseFile"
          @unchoose="unchooseFile"
          @choose-all="chooseAllFile"
          @unchoose-all="unchooseAllFile"
          @open-folder="openFolder"
          :scroll-disabled="true"
          :choosed-inner-files="choosedFiles"
          :current-inner-files="saveFiles"
        />
        <el-empty v-else description="分享已失效~" />
      </template>
      <template v-if="!loading && !shareInfo.nickname">
        <el-empty v-if="shareInfo.isWrongUrl" description="分享链接错误" />
        <div v-else class="no-share-pwd">
          <h3>链接提取码</h3>
          <el-input v-model.trim="sharePwd">
            <template slot="prepend">提取码</template>
          </el-input>
          <el-button type="primary" :disabled="!sharePwd" @click="fetchShareInfo">提取资源</el-button>
          <p class="danger tip" v-if="sharePwd && shareInfo.isWrongPwd">提取码错误</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import FileList from '../../components/FileList.vue'

import {
 getShareInfo, getShareFileList,
} from '../../api/share.js'

export default {
  components: {
    FileList,
  },
   watch: {
    'sharePwd': {
      deep: true,
      handler() {
        this.shareInfo.isWrongPwd = false
      }
    }
  },
  data () {
    return {
      loading: true,
      shareInfo: {},
      shareId: '',
      sharePwd: '',
      parentfid: '',
      pickerBreads: [{
        fid: '',
        name: '分享文件'
      }],
      defaultAvatar: require('@/assets/images/avatar.png'),
    }
  },
  computed: {
    pickerBreadsLength () {
      return this.pickerBreads.length
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() { 
      const query = this.$route.query
      const params = this.$route.params
      const { shareId } = params
      const { pwd: sharePwd } = query
      this.shareId = shareId
      this.sharePwd = sharePwd

      this.$store.dispatch('pan/updateSaveFiles', [])
      this.$store.dispatch('pan/updateChoosedFiles', [])
      this.fetchShareInfo()
    },
    async fetchShareInfo() { 
      const res = await getShareInfo({
        shareId: this.shareId, 
        sharePwd: this.sharePwd
      })
      this.loading = false
      if (res.code === this.CODE.success) { 
        const data = res.data
        this.shareInfo = data
        this.sharePwd = data.pwd
        if (!data.expired) { 
          this.refresh()
        }
      } else if (res.code === this.CODE.share_pwd_error) {
        this.shareInfo = { isWrongPwd: true}
      } else if (res.code === this.CODE.share_id_error) {
        this.shareInfo = { isWrongUrl: true}
      }
    },
    // 实现 filelist 方法
    chooseFile({ fid, reset }) {
      if (reset) {
        this.$store.dispatch('pan/updateChoosedFiles', [fid])
      } else {
        this.$store.dispatch('pan/addChoosedFiles', fid)
      }
    },
    unchooseFile ({ fid }) {
      this.$store.dispatch('pan/removeChoosedFiles', fid)
    },
    chooseAllFile () {
      this.$store.dispatch('pan/updateChoosedFiles', this.saveFiles.map(item => item.fid))
    },
    unchooseAllFile () {
      this.$store.dispatch('pan/updateChoosedFiles', [])
    },
    openFolder({ fid, name }) {
      this.parentfid = fid
      this.refresh()
      this.pickerBreads.push({ fid, name })
    },
    chooseBread({ fid }) { 
      let index = 0
      for (let i = 0; i < this.pickerBreads.length; i++) { 
        const item = this.pickerBreads[i]
        if (fid == item.fid) { 
          index = i
          break
        }
      }
      this.pickerBreads = this.pickerBreads.splice(0, index + 1)
      this.parentfid = fid
      this.refresh()
    },
    // 实现 operation 方法
    async refresh () {
      const res = await getShareFileList({
        shareId: this.shareId,
        parentfid: this.parentfid,
      })
      if (res.code == this.CODE.success) {
        const { files } = res.data
        this.$store.dispatch('pan/updateSaveFiles', files)
        this.$store.dispatch('pan/updateChoosedFiles', [])
      }
    },
    openApp() { 
      window.location.href= `osnd://pan/save-share?sharePwd=${this.sharePwd}&shareId=${this.shareId}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_var.scss";

.share-page {
  height: 100%;
  display: flex; 
  flex-direction: column;
  .nav-bar {
    flex: none;
    background-color: $theme-color;
    .inner {
      max-width: 100%;
      width: $theme-width;
      margin: 0 auto;
      padding: 15px 0;
      .logo {
        height: 30px;
      }
    }
  }
  .share-content {
    flex: auto;
    max-width: 100%;
    width: $theme-width;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  .header {
    padding: 15px 20px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .avatar {
        height: 50px;
        margin-right: 10px;
      }
      .title {
        color: #000;
      }
      .el-breadcrumb {
        margin-top: 6px;
        max-width: 600px;
      }
    }
  }
  .no-share-pwd {
    max-width: 100%;
    width: 440px;
    margin: 80px auto 20px;
    background-color: #fff;
    padding: 30px;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    text-align: center;
    h3 {
      margin-bottom: 15px;
    }
    .el-button {
      width: 80%;
      margin-top: 30px;
    }
    .tip {
      margin-top: 10px;
      font-size: 12px;
    }
  }
}
</style>