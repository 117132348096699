import { render, staticRenderFns } from "./ProtocolUser.vue?vue&type=template&id=57e7af49&scoped=true&"
var script = {}
import style0 from "./ProtocolUser.vue?vue&type=style&index=0&id=57e7af49&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57e7af49",
  null
  
)

export default component.exports