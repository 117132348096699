<template>
  <div class="filelist">
    <div class="file-list__header" v-if='viewMode === "list"'>
      <div class="inner">
        <div class="file-info">
          <!-- 加个外层元素防止点击事件向上冒泡 -->
          <div class="checkbox-wrap" @click.stop="" v-if="showCheckbox">
            <el-checkbox 
              v-model="allFileChoosed" 
              :indeterminate="indeterminate" 
            />
          </div>
          <span class="name">文件名称</span>
        </div>
        <!-- 分享文件的列表下没有大小 -->
        <template v-if="!isShare">
          <div class="file-size">文件大小</div>
        </template>
        
        <template v-if="isShare">
          <div class="file-view">浏览</div>
          <div class="file-mark">转存</div>
        </template>

        <div class="file-time" v-if="isDelete">删除时间</div>
        <div class="file-time" v-else-if="isShare">分享时间</div>
        <div class="file-time" v-else>上次修改时间</div>

        <template v-if="isShare">
          <div class="file-status">状态</div>
        </template>
        <div class="file-folder" v-if="searchView">所在目录</div>
        <div class="file-folder" v-if="isShare">分享链接</div>
      </div>
    </div>
    <div 
      class="file-list__content custom-scroll-bar" 
      v-infinite-scroll="loadMore"
      :infinite-scroll-immediate="false"
      :infinite-scroll-disabled="scrollDisabled"
    >
      <div class="file-list__inner" v-if='currentInnerFiles.length > 0'>
        <file-item 
          class="fileitem"
          v-for="item in currentInnerFiles" 
          :file="item"
          :key="isRemote ? (item.path || item.name) : item.fid"
          :view="view"
          :type="type"
          :show-checkbox="showCheckbox"
          @choose="chooseFile"
          @unchoose="unchooseFile"
          @open-folder="openFolder"
          @remove="deleteFile" 
          @rename="renameFile" 
          @move="moveFile" 
          @download="downloadFile" 
          :checked="choosedInnerFiles.includes(item.fid)"
        />
        <p class="intro">已加载 {{currentInnerFiles.length}} {{ isShare ? '条分享记录' : '个文件/文件夹' }}</p>
      </div>
      <div class="no-file-list" v-if='currentInnerFiles.length == 0'>
        <img src="@/assets/images/download-empty.png" class="no-file-image" alt="暂无文件">
        <p class="tip" v-if="isDelete">回收站暂时没有记录</p>
        <p class="tip" v-else-if="isShare">暂无云盘分享记录</p>
        <p class="tip" v-else>{{ this.searchView ? '没有搜索结果' : '暂无文件' }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import FileItem from './FileItem.vue'
export default {
  components: {
    FileItem,
  },
  mounted () {
    
  },
  props: {
    type: {
      type: String,
      default: 'normal'
    }, 
    view: {
      type: String,
      default: 'normal'
    }, 
    viewMode: {
      type: String,
      default: 'list' // card
    }, 
    showCheckbox: {
      type: Boolean,
      default: true
    },
    scrollDisabled: {
      type: Boolean,
      default: true
    }, 
    currentInnerFiles: {
      type: Array,
      default: () => []
    }, 
    choosedInnerFiles: {
      type: Array,
      default: () => []
    }, 
  },
  computed: {
    isRemote () {
      return this.type === 'remote'
    },
    isDelete () {
      return this.type === 'delete'
    },
    isShare () {
      return this.type === 'share'
    },
    searchView () {
      return this.view === 'search'
    },
    indeterminate () {
      return this.choosedInnerFiles.length > 0 && this.choosedInnerFiles.length < this.currentInnerFiles.length
    },
    allFileChoosed: {
      get () {
        return this.choosedInnerFiles.length > 0 && this.choosedInnerFiles.length === this.currentInnerFiles.length
      },
      set (value) {
        this.$emit(value ? 'choose-all' : 'unchoose-all')
      }
    },
  },
  methods: {
    loadMore () {
      this.$emit('load-more')
    },
    openFolder (data) {
      this.$emit('open-folder', data)
    },
    chooseFile (data) {
      this.$emit('choose', data)
    },
    unchooseFile (data) {
      this.$emit('unchoose', data)
    },
    deleteFile (data) {
      this.$emit('remove', data)
    },
    renameFile (data) {
      this.$emit('rename', data)
    },
    moveFile (data) {
      this.$emit('move', data)
    },
    downloadFile (data) {
      this.$emit('download', data)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';

.filelist {
  display: flex;
  flex: auto;
  overflow: hidden;
  flex-direction: column;
}

.file-list__header {
  padding: 0 20px;
  flex: none;
  .inner {
    display: flex;
    background-color: #fff;
    padding: 8px 10px;
    border-bottom: 1px solid $border-color;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  font-weight: bold;
  color: #666;
  flex-shrink: 0;
  align-items: center;
  font-size: 12px;
  .file-info {
    display: flex;
    flex: auto;
    align-items: center;
    .checkbox-wrap {
      padding-left: 4px;
    }
    .name {
      padding-left: 12px;
    }
  }
  .file-time,
  .file-size {
    width: 16%;
  }
  .file-view,
  .file-mark {
    flex: none;
    width: 60px;
  }
  .file-status {
    width: 90px;
    flex: none;
  }
  .file-folder {
    width: 70px;
    flex: none;
    text-align: center;
  }
}

.file-list__content {
  flex: auto;
  padding-bottom: 15px;
  overflow-y: auto;
  -weikit-overflow-scrolling: touch;
  .file-list__inner {
    padding: 0 20px;
    .file-item-card-wrap {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .intro {
    font-size: 12px;
    color: #666;
    margin-top: 10px;
    text-align: center;
  }
}

.no-file-list {
  flex: auto;
  text-align: center;
  padding-top: 100px;
  .no-file-image {
    max-width: 200px;
    max-height: 200px;
    margin-bottom: 10px;
  }
  .tip {
    font-size: 12px;
    color: #666;
    margin-top: 10px;
  }
}

</style>